import clsx from "clsx";
import ResponsiveFooterDarkV2 from "components/ResponsiveFooterDarkV2";

const Footer: React.FC<{ removeMargin?: boolean }> = ({ removeMargin = false }) => (
	<div
		className={clsx("", {
			"mt-0": removeMargin,
			"mt-[72px]": !removeMargin,
		})}
	>
		<ResponsiveFooterDarkV2 source="Home page" isLight showNewFooter />
	</div>
);

export default Footer;
