/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import { convertToNBLocationDetailFormat } from "../components/ProfileEditFlow/components/EditLocation/utils/helper";
import {
	SECTION_MAP,
	SUB_SECTION_MAP,
	MAX_EXPERIENCE_COUNT_ALLOWED,
	MAX_EDUCATION_COUNT_ALLOWED,
} from "../constants/sectionConstants";
import { FurtherEducationType } from "../types/UserProfileDataType";

const convertMonthsToYearAndMonths = (months: number) => {
	const extras = months % 12;
	const calcMonth = extras === 0 ? "" : `${extras} month${extras !== 1 ? "s" : ""}`;
	const conjuction = extras === 0 ? "" : "and";
	return months < 12
		? calcMonth
		: `${Math.floor(months / 12)} year${
				Math.floor(months / 12) !== 1 ? "s" : ""
		  } ${conjuction} ${calcMonth}`;
};

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

const convertDaysToMonths = (totalDays: number) => {
	const months = Math.floor(totalDays / 30);
	const extras = totalDays % 30;
	const days = extras === 0 ? "" : `${extras} day${extras !== 1 ? "s" : ""}`;
	const conjuction = extras === 0 ? "" : "and";
	return totalDays < 30
		? days
		: `${months} month${months !== 1 ? "s" : ""} ${conjuction} ${days}`;
};

export const getTotalYearFromMonthofExperience = (months: number) =>
	Math.floor((months ?? 0) / 12);
export const getFractionMonthsFromTotalMonthofExperience = (months: number) =>
	(months ?? 0) % 12;
export const getTotalMonthsFromYearAndMonth = (year: number, month: number) =>
	(year ?? 0) * 12 + (month ?? 0);

export const numberWithCommas = (x) =>
	x === null || x.length === 0 || Number.isNaN(Number(x?.toString()))
		? x
		: Number(x.toString()).toLocaleString("en-IN");

const formatDateWithSuffix = (isoString) => {
	if (isoString === null || isoString === undefined || isoString === "") return null
	const date = new Date(isoString);
	const day = date.toLocaleString("default", { day: "numeric" });
	const month = date.toLocaleString("default", { month: "short" });
	const year = date.toLocaleString("default", { year: "numeric" });
	const daySuffix = getDaySuffix(day);
	return `${day}${daySuffix} ${month} ${year}`;
};

const getDaySuffix = (day) => {
	if (day === "11" || day === "12" || day === "13") {
		return "th";
	}
	const lastDigit = day.slice(-1);
	switch (lastDigit) {
		case "1":
			return "st";
		case "2":
			return "nd";
		case "3":
			return "rd";
		default:
			return "th";
	}
};

const docUploaded = (docObj) => {
	const docArr = [];
	const keys = Object.keys(docObj);
	keys.forEach((key) => docArr.push(docObj[key]?.name));

	return docArr;
};

export const candidateDetailsParser = (candidateDetails, missingDetails: any = {}) => {
	const parsedCandidateDetails = {};
	parsedCandidateDetails[SECTION_MAP.PROFILE_EDITOR] = {
		totalExperience: {
			yearsOfExperience: getTotalYearFromMonthofExperience(
				candidateDetails?.months_of_experience
			),
			monthsOfExperience: getFractionMonthsFromTotalMonthofExperience(
				candidateDetails?.months_of_experience
			),
		},
		currentMonthlySalary: candidateDetails?.current_salary_v2,
		currentNoticePeriod: candidateDetails?.notice_period,
		experiences: candidateDetails?.experiences?.reduce((acc, next) => {
			acc[next.id] = next;
			return acc;
		}, {}),
		educations: candidateDetails?.educations?.reduce((acc, next) => {
			acc[next.id] = next;
			return acc;
		}, {}),
		documents: candidateDetails?.docs,
		locationDetails: {
			hometown:
				candidateDetails?.user_location?.hometown?.name ||
				candidateDetails?.hometown,
			area: candidateDetails?.area,
			city: candidateDetails?.city,
		},
		nbLocationDetails: {
			defaultLocation: convertToNBLocationDetailFormat(
				candidateDetails?.user_location,
				"UserLocation"
			),
			isNBMigrated: candidateDetails?.user_location?.source === "NB",
			hometown: candidateDetails?.user_location?.hometown,
		},
		resume: candidateDetails?.resume,
		certificate: candidateDetails?.user_certifications?.map(item => item.certification),
		profile_photo_url: candidateDetails?.photo?.url,
		isExperienced: candidateDetails?.is_experienced,
	};
	parsedCandidateDetails[SECTION_MAP.OVERALL] = {
		slug: candidateDetails?.slug,
		userId: candidateDetails?.user_id,
		highestEducationLevel: candidateDetails?.highest_education_level?.level,
		highestEducationLevelID: parseInt(candidateDetails?.highest_education_level?.id as string) || null,
		mediumOfEducation: candidateDetails?.language_of_education,
		certificate: candidateDetails?.user_certifications?.map(item => item.certification),
		furtherEducationPreferences: {
            higherEducationType: candidateDetails?.higher_education_details?.education_type?.filter((item: any) => item !== FurtherEducationType.NOT_INTERESTED) || [],
            higherEducationMode: candidateDetails?.higher_education_details?.education_mode || [],
            higherEducationDegree: {
                higherEducationDegreeID: candidateDetails?.higher_education_details?.education_course?.id || null,
                higherEducationDegreeName: candidateDetails?.higher_education_details?.education_course?.name || null,
            }
		},
		maxEducationCountReached:
			candidateDetails?.educations?.length >= MAX_EDUCATION_COUNT_ALLOWED,
		resumeDetails: {
			format: candidateDetails?.resume?.format,
			resumeLink: candidateDetails?.resume?.url,
			lastUpdated: formatDateWithSuffix(candidateDetails?.resume?.uploaded_at),
		},
		nbLocationDetails: {
			defaultLocation: convertToNBLocationDetailFormat(
				candidateDetails?.user_location,
				"UserLocation"
			),
			isNBMigrated: candidateDetails?.user_location?.source === "NB",
			hometown: candidateDetails?.user_location?.hometown,
		},
		profileCardDetails: {
			name: candidateDetails?.full_name,
			photoUrlFullPath: candidateDetails?.photo?.url,
			photoURL: candidateDetails?.photo?.path,
			profileURL: candidateDetails?.profile_url,
			isAutoGeneratedPhoto: candidateDetails?.photo?.source === "auto_generated",
			userLevel: {
				label: candidateDetails?.user_level?.full_name,
				icon: candidateDetails?.user_level?.icon_path,
				startColor: candidateDetails?.user_level?.appearance?.start_color,
				endColor: candidateDetails?.user_level?.appearance?.end_color,
				textColor: candidateDetails?.user_level?.appearance?.text_color,
			},
			isExperienced: candidateDetails?.is_experienced,
			highestEducationalLevel: candidateDetails?.highest_education_level?.level,
			educationHeadline:
				candidateDetails?.headline?.education_headline ||
				candidateDetails?.highest_education_level?.level,
			experienceHeadline: candidateDetails?.headline?.experience_headline,
			area: candidateDetails?.user_location
				? candidateDetails?.user_location?.area?.name
				: candidateDetails?.area?.name,
			city: candidateDetails?.user_location
				? candidateDetails?.user_location?.city?.name ||
				  candidateDetails?.user_location?.sub_district?.name
				: candidateDetails?.city?.name || "",
			displayName: candidateDetails?.user_location?.display_name,
			contactInfo: candidateDetails?.contact_info,
		},
		tableDetails: {
			"Email ID": candidateDetails?.contact_info?.email,
			"Mobile number": missingDetails?.phoneNumber,
			"Date of birth": formatDateWithSuffix(candidateDetails?.date_of_birth),
			Dob: candidateDetails?.date_of_birth,
			Gender:
				candidateDetails?.gender === "m"
					? "Male"
					: candidateDetails?.gender === "f"
					? "Female"
					: "Other",
			// Temporary:  Adding user_location check cause BE does not have migration check incase of sub-district
			"Current location": candidateDetails?.user_location
				? candidateDetails?.user_location?.city?.name ||
				  candidateDetails?.user_location?.sub_district?.name
				: candidateDetails?.city?.name || "",
			Hometown:
				candidateDetails?.user_location?.hometown?.name ||
				candidateDetails?.hometown?.name,
		},
	};
	/**
	 * Populate experience details, only provide this section when user has
	 * any experience.
	 */
	const shouldShowProfessionalDetails =
		!!candidateDetails?.experiences?.length ||
		!!candidateDetails?.educations?.length ||
		!!candidateDetails?.skills?.length;

	if (shouldShowProfessionalDetails) {
		parsedCandidateDetails[SECTION_MAP.PROFESSIONAL_DETAILS] = {};
		if (candidateDetails?.experiences?.length) {
			parsedCandidateDetails[SECTION_MAP.PROFESSIONAL_DETAILS][
				SUB_SECTION_MAP.EXPERIENCE.sectionName
			] = {
				Icon: SUB_SECTION_MAP.EXPERIENCE.sectionIcon,
				noticePeriod: convertDaysToMonths(candidateDetails?.notice_period),
				currentlyWorkingInAny:
					candidateDetails?.experiences?.find(
						(experience) =>
							experience.ongoing === true &&
							experience?.employment_type === "full-time"
					) !== undefined,
				maxExperienceCountReached:
					candidateDetails?.experiences?.length >= MAX_EXPERIENCE_COUNT_ALLOWED,
				totalExperience: convertMonthsToYearAndMonths(
					candidateDetails?.months_of_experience
				),
				currentSalary: candidateDetails?.current_salary_v2
					? `₹ ${numberWithCommas(candidateDetails?.current_salary_v2)}`
					: null,
				experiences: candidateDetails?.experiences?.map((experience) => ({
					jobTitle: experience.job_title?.title,
					companyName: experience.organization?.title,
					jobDescription: experience?.job_description,
					skills: experience?.skills,
					startDate: experience.start
						? new Date(experience.start).toLocaleString("en-us", {
								month: "short",
								year: "numeric",
						  })
						: "N/A",
					endDate: experience.ongoing
						? "Present"
						: new Date(experience.end).toLocaleString("en-us", {
								month: "short",
								year: "numeric",
						  }),
					department: experience.department?.title,
					subDepartment: experience.sub_department?.title,
					subDepartmentL2: experience.sub_departments_v2,
					industry: experience.industry?.title,
					employmentType: capitalize(experience.employment_type),
					companyLogoURL: experience?.organization?.gumlet_url,
					id: experience?.id,
				})),
			};
		}
		if (candidateDetails?.educations?.length) {
			parsedCandidateDetails[SECTION_MAP.PROFESSIONAL_DETAILS][
				SUB_SECTION_MAP.EDUCATION.sectionName
			] = {
				Icon: SUB_SECTION_MAP.EDUCATION.sectionIcon,
				highestEducationLevel: candidateDetails?.highest_education_level?.level,
				educations: candidateDetails?.educations?.map((education) => ({
					id: education?.id,
					course: education.course?.title,
					universityName: education.education_institute?.title,
					educationLevel: education.education_level.level,
					specialisation: education.specialization?.title,
					educationType: education.education_type,
					startDate: education?.start
						? new Date(education?.start).toLocaleString("en-us", {
								month: "short",
								year: "numeric",
						  })
						: "N/A",
					endDate: education.ongoing
						? "Present"
						: new Date(education.end).toLocaleString("en-us", {
								month: "short",
								year: "numeric",
						  }),
				})),
			};
		}

		if (candidateDetails?.skills?.length) {
			parsedCandidateDetails[SECTION_MAP.PROFESSIONAL_DETAILS][
				SUB_SECTION_MAP.SKILLS.sectionName
			] = {
				Icon: SUB_SECTION_MAP.SKILLS.sectionIcon,
				skills: candidateDetails?.skills?.map((skill) => ({
					name: skill.skill_name,
					isVerified: skill.verified,
					id: skill.id,
					display_name: skill.skill_name,
					assessment_skill_tag_id: skill.skill_assessment_id,
					image_url: skill.skill_image_url,
					op_verification_status: skill.skill_op_verification_status,
					selectedFrom: skill.selectedFrom,
					skill_id: skill.skill_id,
					skill_name: skill.skill_name,
				})),
			};
		}
	}

	/**
	 * Populate the personal details, only provide this section when user has provided his
	 *  basic details and have known languages
	 */
	const shouldShowPersonalDetails =
		(!!candidateDetails?.date_of_birth && !!candidateDetails?.gender) ||
		!!candidateDetails?.known_languages?.length;

	if (shouldShowPersonalDetails) {
		parsedCandidateDetails[SECTION_MAP.PERSONAL_DETAILS] = {};
		if (!!candidateDetails?.date_of_birth && !!candidateDetails?.gender) {
			parsedCandidateDetails[SECTION_MAP.PERSONAL_DETAILS][
				SUB_SECTION_MAP.BASIC_DETAILS.sectionName
			] = {
				Icon: SUB_SECTION_MAP.BASIC_DETAILS.sectionIcon,
				tableDetails: {
					"Date of birth": {
						value: formatDateWithSuffix(candidateDetails?.date_of_birth),
						isPublic: true,
					},
					Gender: {
						value:
							candidateDetails?.gender === "m"
								? "Male"
								: candidateDetails?.gender === "f"
								? "Female"
								: "Other",
						isPublic: true,
					},
				},
			};
		}

		if (candidateDetails?.known_languages?.length) {
			parsedCandidateDetails[SECTION_MAP.PERSONAL_DETAILS][
				SUB_SECTION_MAP.LANGUAGES_KNOWN.sectionName
			] = {
				Icon: SUB_SECTION_MAP.LANGUAGES_KNOWN.sectionIcon,
				languages: candidateDetails?.known_languages?.map((language) => ({
					languageName: language?.language_name,
					languageId: language?.language_id,
					speakingProficiency:
						language?.language_name === "English"
							? language?.language_skills?.find(
									(skill) => skill?.name === "speak"
							  )?.proficiency
							: undefined,
				})),
			};
		}

		if (candidateDetails?.language_evaluations?.length) {
			parsedCandidateDetails[SECTION_MAP.PERSONAL_DETAILS][
				SUB_SECTION_MAP.LANGUAGES_EVALUATION.sectionName
			] = {
				Icon: SUB_SECTION_MAP.LANGUAGES_EVALUATION.sectionIcon,
				evaluations: candidateDetails?.language_evaluations?.map(
					(evaluation) => ({
						languageName: evaluation?.language?.name,
						languageId: evaluation?.language?.id,
						status: evaluation?.status,
						maxAttempt: evaluation?.max_attempts,
						sourceUrl: evaluation?.preview_url,
						evaluationPassed: evaluation?.evaluation_passed,
					})
				),
			};
		}
	}

	/**
	 * Populate the contact details, only provide this section when user has provided his
	 *  mobile no. and email-id
	 */
	const shouldShowContactDetails =
		!!missingDetails?.phoneNumber || !!candidateDetails?.contact_info?.email;

	if (shouldShowContactDetails) {
		parsedCandidateDetails[SECTION_MAP.CONTACT_DETAILS] = {};
		if (candidateDetails?.contact_info?.email) {
			parsedCandidateDetails[SECTION_MAP.CONTACT_DETAILS][
				SUB_SECTION_MAP.EMAIL.sectionName
			] = {
				Icon: SUB_SECTION_MAP.EMAIL.sectionIcon,
				emailId: candidateDetails?.contact_info?.email,
				emailStatus: candidateDetails?.contact_info?.email_status,
			};
		}

		if (missingDetails?.phoneNumber) {
			parsedCandidateDetails[SECTION_MAP.CONTACT_DETAILS][
				SUB_SECTION_MAP.MOBILE.sectionName
			] = {
				Icon: SUB_SECTION_MAP.MOBILE.sectionIcon,
				mobileNo: missingDetails?.phoneNumber,
			};
		}
	}

	const shouldShowJobDetails =
		(!!candidateDetails?.docs && !!Object.keys(candidateDetails?.docs).length) ||
		candidateDetails?.assets?.length > 0;

	if (shouldShowJobDetails) {
		const docArr = docUploaded(candidateDetails?.docs);
		const assetArr = candidateDetails?.assets;
		if (docArr.length || assetArr.length) {
			parsedCandidateDetails[SECTION_MAP.JOB_DETAILS] = {};
			parsedCandidateDetails[SECTION_MAP.JOB_DETAILS][
				SUB_SECTION_MAP.DOCUMENTS.sectionName
			] = {
				Icon: SUB_SECTION_MAP.DOCUMENTS.sectionIcon,
				documents: docArr,
				assets: assetArr,
			};
		}
	}

	return parsedCandidateDetails;
};

export const candidateJobDetailsParser = (jobDetails) => {
	const parsedJobDetails = {};
	parsedJobDetails[SECTION_MAP.PROFILE_EDITOR] = {
		location_preference: {
			preferred_locations: jobDetails?.job_preferences?.preferred_locations,
			area: jobDetails?.job_preferences?.default_area,
			city: jobDetails?.job_preferences?.default_city,
			default_location: jobDetails?.job_preferences?.default_location,
			preferred_locations_v2: jobDetails?.job_preferences?.preferred_locations_v2,
		},
		job_preference: {
			employmentType: jobDetails?.job_preferences?.preferred_employment_type,
			workplace: jobDetails?.job_preferences?.preferred_workplace,
			shift: jobDetails?.job_preferences?.preferred_shift,
		},
		preferred_job_departments: jobDetails?.job_types,
	};
	const shouldShowJobDetails =
		(!!jobDetails?.job_preferences?.preferred_employment_type?.length &&
			!!jobDetails?.job_preferences?.preferred_shift?.length &&
			!!jobDetails?.job_preferences?.preferred_workplace?.length) ||
		!!jobDetails?.job_types?.length ||
		!!jobDetails?.job_preferences?.preferred_locations?.length;

	if (shouldShowJobDetails) {
		parsedJobDetails[SECTION_MAP.JOB_DETAILS] = {};
		if (
			!!jobDetails?.job_preferences?.preferred_employment_type?.length &&
			!!jobDetails?.job_preferences?.preferred_shift?.length &&
			!!jobDetails?.job_preferences?.preferred_workplace?.length
		) {
			parsedJobDetails[SECTION_MAP.JOB_DETAILS][
				SUB_SECTION_MAP.JOB_PREFERENCES.sectionName
			] = {
				Icon: SUB_SECTION_MAP.JOB_PREFERENCES.sectionIcon,
				preferredEmploymentType:
					jobDetails?.job_preferences?.preferred_employment_type,
				preferredShift: jobDetails?.job_preferences?.preferred_shift,
				preferredWorkplace: jobDetails?.job_preferences?.preferred_workplace,
			};
		}

		if (jobDetails?.job_types?.length) {
			parsedJobDetails[SECTION_MAP.JOB_DETAILS][
				SUB_SECTION_MAP.JOB_TYPES.sectionName
			] = {
				Icon: SUB_SECTION_MAP.JOB_TYPES.sectionIcon,
				jobTypes: jobDetails?.job_types,
			};
		}

		if (jobDetails?.job_types_v2?.length) {
			parsedJobDetails[SECTION_MAP.JOB_DETAILS][
				SUB_SECTION_MAP.PREFERRED_JOB_TITLE.sectionName
			] = {
				Icon: SUB_SECTION_MAP.PREFERRED_JOB_TITLE.sectionIcon,
				preferredJobTitle: jobDetails?.job_types_v2,
			};
		}

		if (jobDetails?.job_preferences?.preferred_locations?.length || jobDetails?.job_preferences?.preferred_locations_v2?.length) {
			parsedJobDetails[SECTION_MAP.JOB_DETAILS][
				SUB_SECTION_MAP.LOCATION.sectionName
			] = {
				Icon: SUB_SECTION_MAP.LOCATION.sectionIcon,
				jobLocations: jobDetails?.job_preferences?.preferred_locations,
				jobLocationsV2: jobDetails?.job_preferences?.preferred_locations_v2,
			};
		}
	}

	return parsedJobDetails;
};
