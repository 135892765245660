import { NextRouter } from "next/router";
import { ADVANTAGE_SESSION_STORAGE_KEYS } from "./constants";

// eslint-disable-next-line import/prefer-default-export
export const isApnaAdvantagePage = (pathname) => pathname.startsWith("/apna-advantage");

export function convertToTitleCase(text: string): string {
	if (text) {
		return text
			.split("-")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	}
	return "";
}

export const downloadBrochureAndRedirect = (
	router: NextRouter,
	brochureUrl: string,
	advantageCourseLink: string
) => {
	sessionStorage?.removeItem(ADVANTAGE_SESSION_STORAGE_KEYS.DOWNLOAD_BROCHURE_ENABLED);
	if (advantageCourseLink) {
		window.open(brochureUrl, "_blank");
		setTimeout(() => {
			router.push(advantageCourseLink);
		}, 100);
		return;
	}

	window.open(brochureUrl, "_blank");
};
