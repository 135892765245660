import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { bpMax } from "theme";
import { isCanvasMicroSite, isHostLaunchedMicrosite, logoutUser } from "utils";
import Image from "next/image";
import { CircularProgress, Popover } from "@material-ui/core";
import { useRouter } from "next/router";
import config from "config";
import { getScreenNameFromRoute, getUrlPath } from "utils/helpers";
import { SESSION_STORAGE_KEYS } from "utils/constants";
import DownArrow from "assets/svgs/DownArrow";
import LogoutIcon from "assets/svgs/LogoutIcon";
import LoggedinUserIcon from "assets/svgs/LoggedinUserIcon";
import useJobFeedStore from "components/RevampFeedDetails/JobFeed/store";
import PersonIcon from "assets/svgs/PersonIcon";
import clsx from "clsx";
import { useCandidateDetails } from "../../modules/candidate/profile/hooks/useCandidateDetails";

const UserAvatar = ({
	isDark = false,
	refetchProfileHeaderComplete = null,
	showOnlyAvatar = false,
	showOutline = false,
	isAdvantagePage = false,
}) => {
	const { resetSearch } = useJobFeedStore();
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);

	const anchorRef = useRef(null);

	const {
		data: profileDetails,
		isLoading: isProfileLoading,
		refetch: refetchProfileDetails,
	} = useCandidateDetails("", true);
	useEffect(() => {
		if (refetchProfileHeaderComplete) {
			refetchProfileDetails();
			refetchProfileHeaderComplete?.();
		}
	}, [refetchProfileHeaderComplete]);

	const imageURL = profileDetails?.photoURL
		? `${config.gumletBaseUrl}/${profileDetails?.photoURL}`
		: "https://cdn.apna.co/cloudinary/job_detail_page_images/defaultUserImg.jpg";

	const router = useRouter();
	const AVATAR_OPTIONS = [
		!isCanvasMicroSite(router.pathname)
			? {
					id: 0,
					label: "View Profile",
					link: "/candidate/profile",
					icon: <LoggedinUserIcon />,
			  }
			: null,
		!isHostLaunchedMicrosite()
			? {
					id: 1,
					label: "Logout",
					link: "/logout",
					icon: <LogoutIcon />,
			  }
			: null,
	];

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (anchorRef.current && !anchorRef.current.contains(event.target)) {
				setOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [anchorRef]);

	const onAvatarClick = (event) => {
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Profile Icon Clicked", {
				Screen: getScreenNameFromRoute(getUrlPath()),
			});
		});
		setOpen(!open);
	};

	const onViewProfileClicked = () => {
		resetSearch();
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("View Profile Clicked", {
				Screen: getScreenNameFromRoute(getUrlPath()),
			});
		});
		router.push("/candidate/profile");
	};

	const onLogoutClicked = () => {
		resetSearch();
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Logout Clicked", {
				Screen: getScreenNameFromRoute(getUrlPath()),
			});
			Mixpanel.default.reset();
		});
		/**
		 * Once the user is logged out, we are redirecting them back to
		 * job listing page, in case he is in any candidate specific route.
		 */
		if (typeof window !== "undefined") {
			sessionStorage.removeItem(SESSION_STORAGE_KEYS.SEARCH_EVENTS_PROPS);
			sessionStorage.removeItem(SESSION_STORAGE_KEYS.PREFILLED_LOCATION);
		}
		if (router.pathname?.includes("/candidate/")) {
			logoutUser({ reload: false, isCandidateRoute: true });
		} else logoutUser();
	};

	const onProfileOptionClicked = (e) => {
		const { target } = e;
		const selectedOptionId = target?.getAttribute("data-id");
		switch (selectedOptionId) {
			case "0":
				onViewProfileClicked();
				break;
			case "1":
				onLogoutClicked();
				break;
			default:
		}
	};
	if (isProfileLoading) {
		return null;
	}

	if (showOnlyAvatar) {
		return (
			<div
				className={clsx("h-[24px] w-[24px] rounded-full", {
					"border-[2px] border-solid border-[#1F8268]": showOutline,
				})}
			>
				<Image
					key={imageURL}
					src={imageURL}
					alt="User Profile Image"
					// loading="lazy"
					width="100%"
					height="100%"
					objectFit="cover"
					style={{ borderRadius: "50%" }}
				/>
			</div>
		);
	}

	return (
		<ProfileContainer ref={anchorRef}>
			<AvatarContainer onClick={onAvatarClick} isAdvantagePage={isAdvantagePage}>
				{/* <AvatarName isDark={isDark}>
					{profileDetails?.name || "Your profile"}
				</AvatarName> */}
				<AvatarImageContainer isAdvantagePage={isAdvantagePage}>
					<Image
						key={imageURL}
						src={imageURL}
						alt="User Profile Image"
						loading="lazy"
						width="100%"
						height="100%"
						objectFit="cover"
						style={{ borderRadius: "50%" }}
					/>
				</AvatarImageContainer>
				<DownArrow />
			</AvatarContainer>
			{open && (
				<PopoverContainer
					onClick={() => {
						setAnchorEl(!anchorEl);
					}}
					className={open ? "open z-[9999]" : ""}
				>
					<MenuContainer onClick={onProfileOptionClicked}>
						{AVATAR_OPTIONS.map((option) => (
							// eslint-disable-next-line react/jsx-no-useless-fragment
							<>
								{option && (
									<OptionContainer key={option.id}>
										{option.icon}
										<MenuOption data-id={option.id}>
											{option.label}
										</MenuOption>
									</OptionContainer>
								)}
							</>
						))}
					</MenuContainer>
				</PopoverContainer>
			)}
		</ProfileContainer>
	);
};

// styles
const AvatarContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: #fff;
	width: ${({ isAdvantagePage }) => (isAdvantagePage ? "60px" : "68px")};
	border-radius: 24px;
	border: 1px solid #e8e7ea;
	padding: 4px;
`;

const AvatarImageContainer = styled.div`
	height: ${({ isAdvantagePage }) => (isAdvantagePage ? "24px" : "36px")};

	width: ${({ isAdvantagePage }) => (isAdvantagePage ? "24px" : "36px")};

	border-radius: 50%;
	background-color: gray;
	${bpMax.md} {
		width: 24px;
		height: 24px;
	}
`;

const AvatarName = styled.span`
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	color: ${({ isDark }) => (!isDark ? "#021330" : "#fff")};
	margin-right: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 200px;
	${bpMax.md} {
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		margin-right: 8px;
		max-width: 100px;
	}
`;

const MenuOption = styled.div`
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: #190a28;
	margin-bottom: 16px;
	cursor: pointer;
	margin-left: 10px;
	& :last-child {
		margin-bottom: 0;
	}
`;

const MenuContainer = styled.div`
	padding: 20px;
	border-radius: 16px;
	${MenuOption} {
		margin-bottom: 0px;
	}
`;

const OptionContainer = styled.div`
	display: flex;
	border-radius: 16px;
	margin-bottom: 16px;
	&:last-child {
		margin-bottom: 0;
		margin-left: -3px;
	}
`;

const ProfileContainer = styled.div`
	position: relative;
	display: inline-block;
`;
const PopoverContainer = styled.div`
	background-color: #fff;
	box-shadow: 0px 16px 32px 0px #0000000a;
	border-radius: 16px;
	border: 1px solid #e8e7ea;
	margin-top: 4px;
	position: absolute;
	right: 0;
	width: max-content;
	opacity: 0;
	transform: translateY(-20px);
	transition: opacity 0.5s ease, transform 0.5s ease;
	&.open {
		opacity: 1;
		transform: translateY(0);
	}
`;

export default UserAvatar;
