/* eslint-disable @next/next/no-img-element */
import CrossIcon16 from "assets/svgs/CrossIcon16";
import Image from "next/image";
import { useState } from "react";
import { getUrlPath } from "utils/helpers";
import { useWindowResize } from "utils/hooks";
import Mixpanel from "utils/Mixpanel";

const WebToAppNudge = () => {
	const [isMobileView] = useWindowResize();
	const [isOpen, setIsOpen] = useState(true);

	const handleDownloadClick = () => {
		Mixpanel.track("Download app click", {
			page: getUrlPath(),
			Section: "Floating Nudge",
			pageType: "download apna app",
		});

		window.open(
			"https://play.google.com/store/apps/details?id=com.apnatime&referrer=utm_source%3DApna_main_website%26utm_medium%3DDownload_app_button_mWeb%26utm_campaign%3DFloating_nudge"
		);
	};
	if (isOpen) {
		return (
			<>
				<div className="fixed bottom-0 left-0 z-20 flex w-full flex-col space-y-[12px] rounded-t-[12px] border border-solid border-[#DFE1E6] bg-white p-[12px] md:hidden">
					<p className="m-0 text-center text-[14px] font-semibold leading-[20px]">
						3,432 people downloaded our app in last 1 hour!
					</p>
					<button
						className="rounded-[4px] bg-[#1F8268] px-[16px] py-[10px] text-center text-[14px] font-semibold leading-[20px] text-white"
						type="button"
						aria-label="download app button"
						onClick={handleDownloadClick}
					>
						Download Now
					</button>
				</div>
				<div className="fixed inset-x-0 bottom-14 z-20 mx-auto hidden w-full max-w-[1280px] md:block">
					<div className="mx-[16px] flex w-full max-w-[327px] space-x-[12px] rounded-[12px] rounded-t-[12px] border border-solid border-[#DFE1E6] bg-white p-[12px] shadow-sm">
						<div className="h-[82px] w-[82px]">
							<Image
								className="border bg-white object-contain p-2"
								src="https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/app-qr.png"
								alt="Scan to download"
								height={82}
								width={82}
							/>
						</div>
						<div className="h-max flex-1 space-y-[4px]">
							<p className="m-0 text-[14px] font-semibold leading-[20px]">
								3,432 people downloaded our app in last 1 hour!
							</p>
							<img
								className="-ml-2"
								width={104}
								src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
								alt="Get it on google play"
								id="playstore-icon"
							/>
						</div>
						<button
							type="button"
							onClick={() => setIsOpen(false)}
							aria-label="close web to app nudge"
							className="h-max"
						>
							<CrossIcon16 />
						</button>
					</div>
				</div>
			</>
		);
	}
	return null;
};

export default WebToAppNudge;
